import { Routes, Route } from 'react-router-dom';

import './styles/App.css';
import './styles/page.css';
import './styles/footer.css';
import './styles/home.css';
import './styles/navbar.css';

import './component/LoadFadeIn';
import './component/FadeIn';

import Home from './component/Home';

import HowVaccinesWork from './component/HowVaccinesWork';
import History from './component/History';
import CommonQuestions from './component/CommonQuestions';
import VaccineService from './component/VaccineService';
import VaccineSafety from './component/VaccineSafety';
import Recommendations from './component/Recommendations';


function App() {
  return (
      <Routes>
        <Route path="*" element={<Home />} />

          <Route path="como-funcionan-las-vacunas" element={<HowVaccinesWork/>} />
          <Route path="historia" element={<History/>} />
          <Route path="preguntas-comunes" element={<CommonQuestions/>} />
          <Route path="servicio-de-encontrar-vacunas" element={<VaccineService/>} />
          <Route path="seguridad-de-las-vacunas" element={<VaccineSafety/>} />
          <Route path="recomendaciones-medicas" element={<Recommendations/>} />
      </Routes>
  );
}

export default App; 
