import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from './LoadFadeIn';
import { FadeIn } from './FadeIn';
import NavBar  from './Navbar';
import Footer from './Footer';
import {Helmet} from "react-helmet";
import JotformEmbed from 'react-jotform-embed';

const VaccineService = () => {

    useEffect(() => {

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="vaccine-safety-page">
            <div className='row no-gutters'>
                <NavBar />
                <Helmet>
                    <title>Servicio De Encontrar Vacunas</title>
                </Helmet>
                <div className='col-lg-10'>
                    <section className='page-header'>
                    <div className="page-header-img-body my-5">
                        <img className='page-header-img' src='assets/vacunas-finding-header.webp' alt='Header'></img>
                    </div>   
                    <div className='page-header-title'>
                        <h1 className='fade-in-up-load'>Servicio De Encontrar Vacunas</h1>
                    </div>             
                    </section>
                    <div id="free">
                        <h1>100% <span>GRATIS</span></h1>
                    </div>
                    <section id='service-section' className='visit-us-section'>
                        <div className='container header'>
                            <h1>Cómo Funciona</h1>
                            <p>El Servicio De Encontrar Vacunas es un servicio completamente gratis que ofrece Vacunas Para Todos. Este Servicio Funciona de 
                                la Siguiente Manera: El Usuario que está buscando una cita llena el Formulario con la información necesaria y nosotros nos 
                                encargamos de crearle la cita.
                            </p>
                        </div>
                        {/* <div className='container security'>
                            <strong>
                                <p>
                                Por favor contestar las siguientes preguntas: Le Garantizamos que su información no sera compartida con terceros. 
                                </p>
                            </strong>
                        </div> */}
                        <div id="service-form">  
                        <div className='jotform'>
                            <JotformEmbed src="https://form.jotform.com/232767923466165" />
                        </div>
                            {/* <form name="Contact" 
                                method="POST" 
                                data-netlify="true" 
                                netlify-honeypot="bot-field"
                                id="contact"
                                >
                                <div>  
                                    <input type="hidden" name="form-name" value="Contact" />
                                    <input type="hidden" name="subject" 
                                    value="Form Submission from Website" />
                                    <div className="form-group">
                                        <label className='form-label'>Nombre Completo del Paciente</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="first_name"
                                            name="Nombre Completo del Paciente"
                                            placeholder='Your Answer'
                                        />
                                        <br/> 
                                        <label className='form-label'>Fecha de Nacimiento</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="birth_date"
                                            name="Fecha de Nacimiento"
                                            placeholder='Date'
                                        />
                                        <br/>
                                        <label className='form-label'>¿Hombre o Mujer?</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            id="gender" 
                                            name="¿Hombre o Mujer?"
                                            placeholder='Your Answer'
                                        />
                                        <br/>
                                        <label className='form-label'>Dirección Paciente (Por favor incluir el Zip Code y la dirección completa).</label>
                                        <input
                                            type="address"
                                            className="form-control"
                                            id="address"
                                            name="Dirección Paciente"
                                            placeholder='Your Answer'
                                        />
                                        <br/>
                                        <label className='form-label'>Número de Teléfono del Paciente</label>
                                        <input 
                                            type="tel" 
                                            className="form-control"
                                            id="telephone" 
                                            name="Número de Teléfono del Paciente"
                                            placeholder='(XXX) - XXX - XXXX'
                                        />
                                        <br/>
                                        <label className='form-label'>¿Quién es el Contacto de Emergencia del Paciente?</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="emergency_contact"
                                            name="Contacto de Emergencia del Paciente"
                                            placeholder='Your Answer'
                                        />
                                        <br/>
                                        <label className='form-label'>Número de Teléfono del Contacto de Emergencia</label>
                                        <input 
                                            type="tel" 
                                            className="form-control"
                                            id="emergency_contact" 
                                            name="Número de Teléfono del Contacto de Emergencia"
                                            placeholder='(XXX) - XXX - XXXX'
                                        />
                                        <br/>
                                        <label className='form-label'>¿El Paciente es Miembro de Medicaid? (Si es por favor incluir el numero de Medicaid)?</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="medicare"
                                            name="El Paciente es Miembro de Medicaid"
                                            placeholder='Your Answer'
                                        />
                                        <br/>
                                        <label className='form-label'>Últimos Cuatro Dígitos del Social Security Del Paciente (si no lo tienen colocar 0000)</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            id="ssn" 
                                            name="Últimos Cuatro Dígitos del Social Security Del Paciente"
                                            placeholder='XXXX'
                                        />
                                        <br/>
                                        <label className='form-label'>¿Eres Hispano / Latino?</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            id="hispanic" 
                                            name="Eres Hispano / Latino"
                                            placeholder='Your Answer'
                                        />
                                        <div className='container security'>
                                            <strong>
                                                <p>
                                                Si el paciente sufre de cualquier tipo de Alergias por favor listarlas en la próxima pregunta.
                                                </p>
                                            </strong>
                                        </div>
                                        <label className='form-label'>¿El Paciente tiene Alergias?</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="allergy"
                                            name="El Paciente tiene Alergias"
                                            placeholder='Your Answer'
                                        />
                                        <br/>
                                        <label className='form-label'>¿El Paciente tiene Alguna Condición Médica? (Si tiene, por favor declararla)</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            id="conditions" 
                                            name="El Paciente tiene Alguna Condición Médica"
                                            placeholder='Your Answer'
                                        />
                                        <br/>
                                        <label className='form-label'>¿El Paciente es Alérgico a los Huevos?</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            id="egg" 
                                            name="El Paciente es Alérgico a los Huevos"
                                            placeholder='Your Answer'
                                        />
                                        <div className='container security'>
                                            <strong>
                                                <p>
                                                En las siguientes preguntas sobre a qué día y qué hora podría, no son una garantía de que en 
                                                esas fechas y horas habrán citas disponibles. Intentaremos que sea lo más cercano 
                                                posible a lo solicitado.
                                                </p>
                                            </strong>
                                        </div>
                                        <label className='form-label'>¿Qué días está disponible?</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            id="days" 
                                            name="Qué días está disponible"
                                            placeholder='Monday - Friday'
                                        />
                                        <br/>
                                        <label className='form-label'>¿A qué horas podría ir?</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            id="time" 
                                            name="A qué horas podría ir"
                                            placeholder='XX:XX AM / XX:XX PM'
                                        />
                                    </div>                                                                               
                                </div>
                            <div>     
                        </div>
                        <br/>
                        <button type="submit" id="contact-us-submit-btn" className="main-button">Submit</button> 
                    </form> */}
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default VaccineService;