import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from './LoadFadeIn';
import { FadeIn } from './FadeIn';
import NavBar  from './Navbar';
import Footer from './Footer';
import {Helmet} from "react-helmet";


const VaccineSafety = () => {

    useEffect(() => {

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="vaccine-safety-page">
            <div className='row no-gutters'>
                <NavBar />
                <Helmet>
                    <title>Seguridad de las Vacunas</title>
                </Helmet>
                <div className='col-lg-10'>
                    <section className='page-header'>
                        <div className="page-header-img-body my-5">
                            <img className='page-header-img' src='assets/vacunas-safe.webp' alt='Header'></img>
                        </div>   
                        <div className='page-header-title'>
                            <h1 className='fade-in-up-load'>¿Qué tan Seguras son las Vacunas contra el Covid-19?</h1>
                        </div>             
                    </section>
                    <section>
                        <div>
                            <div className='container security'>
                                <h1>Seguridad</h1>
                                <p>
                                Todas las vacunas aprobadas en EE.UU son muy seguras. Antes de ser aprobadas, los fabricantes 
                                tiene que hacer pruebas con mas de diez mil personas para ver qué tan efectivas son y si 
                                causan algún efecto secundario. Las vacunas se demoraron más de un año en ser 
                                desarrolladas. Durante el proceso de aprobación todas las Agencias Federales 
                                que tiene que ver con Salud son consultadas para ver si son aprobadas o no.
                                </p>
                            </div>
                            <div className='row container vaccine-safety'>
                                <div className='col-lg-6'>
                                    <img className='black-white-img' loading="lazy" decoding='async' src="assets/vacunas-blackwhite.webp" alt="home-image"/>
                                </div>
                                <div className='col-lg-6'>
                                    <img loading="lazy" decoding='async' src="assets/vacunas-vaccine-shot.webp" alt="home-image"/>
                                    <div className='security-text'>
                                        <h1>¿La vacuna te da COVID-19?</h1>
                                        <p>
                                            No, las vacunas aprobadas en EE.UU no usan el virus de verdad para entrenar el sistema 
                                            inmunológico. Las Vacunas ayudan a prevenir el contagio de Covid-19.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default VaccineSafety;