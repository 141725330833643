import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from './LoadFadeIn';
import { FadeIn } from './FadeIn';
import NavBar  from './Navbar';
import Footer from './Footer';
import {Helmet} from "react-helmet";

const Recommendations = () => {

    useEffect(() => {

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="recommend-page">
            <div className='row no-gutters'>
                <NavBar />
                <Helmet>
                    <title>Recomendaciones Médicas</title>
                </Helmet>
                <div className='col-lg-10'>
                    <section className='page-header'>
                        <div className="page-header-img-body my-5">
                            <img className='page-header-img' src='assets/vacunas-med-header.jpg' alt='Header'></img>
                        </div>   
                        <div className='page-header-title'>
                            <h1 className='fade-in-up-load'>Recomendaciones Médicas</h1>
                        </div>             
                    </section>
                    <section>
                        <div>
                            <div className='row container recommendations'>
                                <div className='col-lg-6'>
                                    <img className='black-white-img first' src="assets/vacunas-med-top.webp" alt="home-image"/>
                                    <img className='black-white-img' src="assets/vacunas-motherdaughter.webp" alt="home-image"/>
                                    <img className='black-white-img third' src="assets/vacunas-med-bottom.webp" alt="home-image"/>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='security-text'>
                                        <h1>El proceso de desarrollar las vacunas "paso por el proceso cientifico que se debe pasar" - Dr. Juan Riviera MD</h1>
                                        <p>
                                        Uno de los mitos sobre las vacunas contra el COVID-19 es que se desaroyan muy rapido y como resultado no son seguras. Eso 
                                        es completamente falso, como lo dice el Dr. Juan Riviera, un medico reconocido, el proceso de desaroyo cumplio con todos 
                                        los requerimientos de las Agencias de Salud de los Estados Unidos.
                                        </p>
                                    </div>
                                    <div className='security-text'>
                                        <h1>La Vacunacion contra COVID-19 no solo ayuda a prevenir la enfermedad si no tambien "reducen el riesgo de contraer 
                                            una enfermedad crónica por COVID-19" como problemas respiratorios hacia el futuro, Departamento De Salud Del Contado 
                                            De Los Angeles.
                                        </h1>
                                        <p>
                                        A lo que se refiere el Departamento De Salud Del Contado De Los Angeles es que cuando a uno le da COVID-19 hay una muy 
                                        alta possibilidad de que uno quede con problemas de salud en el futuro a base del COVID-19 incluyendo Dificultad 
                                        Respiratoria, Depression, y Problemas Cardiovasculares.
                                        </p>
                                    </div>
                                    <div className='security-text'>
                                        <h1>Las vacunas ayudan a prevenir que el virus que causa COVID-19 se esparsa de persona a persona. Por esa razon 
                                            "hay que vacunarse lo mas rápido possible" para prevenir que el virus se mutue y se vuelva mas morta. 
                                        </h1>
                                        <p>
                                        Una de las razones mas importantes por lo cual toca vacunarse es que si uno no esta vacunado es mucho mas probable 
                                        que a uno le de el virus y si a uno le da uno lo puede esparcir mucho mas fácil. Entre mas se esparca el virus 
                                        mas opportunidades tiene para replicarse y de eso resultan nuevas mutaciones. Una mutacion tiene la capacidad de 
                                        evadir la vacuna pero eso todavia no ha occurido con las variantes que existen hoy en dia, pero entre mas bajo 
                                        sea el nivel de vaccunacion es mas probable que occura.
                                        </p>
                                    </div>
                                    <div className='security-text'>
                                        <h1>El Procesó de desarrollo de las vacunas fue sometido al "monitoreo de seguridad mas intensivo en la historia de los EE.UU" CDC.</h1>
                                        <p>
                                        Lo que esto quiere decir el CDC es que las vacunas encontra de COVID-19 approvadas en EE.UU no solo son igual de 
                                        seguras que cualquier otra vacuna usada contra otras enfermedades si no que son hasta mas seguras por el monitoreo de seguridad tan diligente. 
                                        </p>
                                    </div>
                                    <div className='security-text'>
                                        <h1>Las vacunas son effectivas previniendo COVID-19 y como resultado las vacunas te permiten "ser mas libre en tus movimientos"</h1>
                                        <p>
                                        A lo que se refiere el doctor es que como resultado de la vacuna ahora uno esta un paso mas cerca a la normalidad pre-pandemica. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='credits'>
                                <h1>Credits:</h1>
                                <p>
                                People En Español: 
                                <span>
                                    <a href='https://peopleenespanol.com/noticias/dr-juan-rivera-contesta-preguntas-desmiente-mitos-covid-vacunas'
                                        target="_blank"
                                    >
                                    https://peopleenespanol.com/noticias/dr-juan-rivera-contesta-preguntas-desmiente-mitos-covid-vacunas
                                    </a>
                                </span>
                                </p>
                                <p>
                                CDC, Benefits of getting vaccinated against COVID-19: 
                                <span>
                                    <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/vaccine-benefits.html"
                                        target="_blank"
                                    >
                                    https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/vaccine-benefits.html
                                    </a>
                                </span>
                                </p>
                                <p>
                                Los Angeles County, COVID-19 VACCINES - FREQUENTLY ASKED QUESTIONS (FAQ): 
                                <span>
                                    <a  href="http://www.publichealth.lacounty.gov/media/coronavirus/docs/about/FAQ-Vaccine-Spanish.pdf"
                                        target="_blank"
                                    >
                                    http://www.publichealth.lacounty.gov/media/coronavirus/docs/about/FAQ-Vaccine-Spanish.pdf
                                    </a>
                                </span>
                                </p>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default Recommendations;