import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from './LoadFadeIn';
import { FadeIn } from './FadeIn';
import NavBar  from './Navbar';
import Footer from './Footer';
import {Helmet} from "react-helmet";

const CommonQuestions = () => {

    useEffect(() => {

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="common-questions-page">
            <div className='row no-gutters'>
                <NavBar />
                <Helmet>
                    <title>Preguntas Comunes</title>
                </Helmet>
                <div className='col-lg-10'>
                    <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/vacunas-common-questions-header.webp' alt='Header'></img>
                </div>   
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Preguntas Comunes</h1>
                </div>             
            </section>
            <section>
                <div>
                    <div className='row container questions'>
                        <div className='question-marks col-lg-3'>
                            <i class="fa fa-question fa-3x"></i>
                        </div>
                        <div className='col-lg-9'>
                            <div className='security-text'>
                                <h1>¿Cómo Funciona el Servicio de agendar citas?</h1>
                                <p>
                                El servicio de agendamiento de citas funciona de la siguiente manera: El Usuario que está tratando de vacunarse puede 
                                llenar el Formulario con su información. Cuando le hayamos agendado una Cita les enviamos la información de esta 
                                por mensaje de texto.
                                </p>
                            </div>
                        </div>
                        <div className='question-marks col-lg-3'>
                            <i class="fa fa-question fa-3x"></i>
                        </div>
                        <div className='col-lg-9'>
                            <div className='security-text'>
                                <h1>¿Cuánto Cuesta el Servicio?</h1>
                                <p>
                                El servicio de agendamiento de citas es completamente gratis. Lo único que tiene que hacer el cliente es 
                                llenar el Formulario con la Información necesaria.
                                </p>
                            </div>
                        </div>
                        <div className='question-marks col-lg-3'>
                            <i class="fa fa-question fa-3x"></i>
                        </div>
                        <div className='col-lg-9'>
                            <div className='security-text'>
                                <h1>¿Qué Pasa Con La Información Ingresada?</h1>
                                <p>
                                La Información que es ingresada en el Formulario es solo la información que es requerida para hacer una cita. 
                                Vacunas Para Todos es la única organización que tiene acceso a esa información. Una vez realizada la 
                                cita la información se destruye.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    </div>
</div>
    );
}

export default CommonQuestions;