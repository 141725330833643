import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from './LoadFadeIn';
import { FadeIn } from './FadeIn';
import NavBar  from './Navbar';
import Footer from './Footer';
import {Helmet} from "react-helmet";

const History = () => {

    useEffect(() => {

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id='history-page'>
            <div className='row no-gutters'>
                <NavBar />
                <Helmet>
                    <title>Historia</title>
                </Helmet>
                <div className='col-lg-10'>
                    <section className='page-header'>
                        <div className="page-header-img-body my-5">
                            <img className='page-header-img' src='assets/vacunas-beginning-header.webp' alt='Header'></img>
                        </div>   
                        <div className='page-header-title'>
                            <h1 className='fade-in-up-load'>Nuestro Comienzo</h1>
                        </div>             
                    </section>
                    <section className="home-about-section">
                        <div class="conainer">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div class="container home-about-content">
                                        <div class="home-about-content-text">
                                            <h1>Una Corta Historia</h1>
                                            <p>
                                            Este Proyecto inició cuando se hizo evidente los bajos números de vacunación contra COVID-19 de la comunidad Latina de Palm Beach y Martin County.
                                            </p>
                                            <p>
                                            Investigando, nos dimos cuenta que el eje central del problema era que la comunidad no tenía la información necesaria en español para poder tomar la decisión de vacunarse. Tenían una desconfianza grande en el tema de las vacunas en general.
                                            </p>
                                            <p>
                                            Por esta razón nos pusimos en la tarea de identificar a los miembros de la comunidad, educarlos sobre los beneficios de la vacuna, y cuando lo requieren, agendarles las citas.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 about-img'>
                                    <img loading="lazy" decoding='async' src="assets/vacunas-motherandson.jpg" alt="home-image"/>
                                </div>
                            </div>
                            <div className='container history-now'>
                                <h1>Hoy en Día:</h1>
                                <p>Hoy en día hemos ayudado a muchos miembros de la comunidad Latina a vacunarse.</p>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default History;