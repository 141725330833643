import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from './FadeIn';
import { LoadFadeIn } from './LoadFadeIn';
import NavBar  from './Navbar';
import Footer from './Footer';
import {Helmet} from "react-helmet";

const Home = () => {

    useEffect(() => {

        LoadFadeIn();
        FadeIn();

        // COUNTER NUMBER

        const counters = document.querySelectorAll(".counter");

        counters.forEach((counter) => {
          counter.innerText = "0";
          const updateCounter = () => {
            const target = +counter.getAttribute("data-target");
            const count = +counter.innerText;
            const increment = target / 300;
            if (count < target) {
              counter.innerText = `${Math.ceil(count + increment)}`;
              setTimeout(updateCounter, 1);
            } else counter.innerText = target;
          };
          updateCounter();
        });
        

    }, []);

    return ( 
        <div> 
            <div className='row no-gutters'>
                <NavBar />
                <Helmet>
                    <title>Vacunas Para Todos</title>
                </Helmet>
                <div id="hero-page" className='col-lg-10'>
                    <section id="home-hero-section">
                        <div id="home-hero-image-body">
                            <img src="assets/vacunas-hero.webp" alt="home-image"/>
                            <div id="home-hero-content">
                                <div class="home-hero-content">
                                    <div class="home-hero-content-text">
                                        <h1 className='fade-in-up-load'>Vacunas Para Todos</h1>
                                        <p className='fade-in-up-load'>
                                        Ayudando a La Comunidad Latina a Vacunarse.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>       
                        </section>
                        <section id="home-services-section">
                            <div className='container'>  
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <div class="card">                              
                                            <h2 className='counter' data-target="1"></h2>
                                            <p className='mission-1'> 
                                            Informar a la Comunidad Latina de Palm Beach y Martin County de la Importancia de Vacunarse contra el Covid-19.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div class="card">                                                          
                                            <h2 className='counter' data-target="2"></h2>
                                            <p className='mission-2'>
                                            Explicarle a la Comunidad Latina de Palm Beach y Martin County cómo funcionan las tres vacunas aprobadas en EE.UU contra el Covid-19, la de Pfizer/BioNTech, Johnson & Johnson, y la de Moderna, así como sus riesgos y beneficios.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div class="card">                              
                                            <h2 className='counter' data-target="3"></h2>
                                            <p>
                                            Si deciden que se quieren Vacunar contra el Covid-19, Vacunas Para Todos los ayudará a conseguir citas usando la información suministrada por el usuario.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="home-about-section">
                            <div class="conainer">
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div class="container home-about-content">
                                            <div class="home-about-content-text">
                                                <h1 className='fade-in-up'>La Misión de Vacunas Para Todos</h1>
                                                <p className='fade-in-up'>
                                                La misión de Vacunas Para Todos es informar a la comunidad Latina de Palm Beach y Martin County en Español la importancia de Vacunarse contra el Covid-19, cómo funcionan las diferentes Vacunas, y ayudar a conseguir citas para su aplicación.
                                                </p>
                                            </div>
                                            <a target="_blank" className="main-button" href="/historia">Aprende más 
                                            </a>                   
                                        </div>
                                    </div>
                                    <div className='col-lg-6 about-img'>
                                        <img src="assets/vacunas-home-mission.webp" alt="home-image"/>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="home-why-us-section">
                            <div id="home-why-us-image-body">
                                <img decoding='async' loading='lazy'  src="assets/vacunas-hero-questions.webp" alt="explore local image"/>
                                <div id="home-why-us-content">
                                    <div className="home-why-us-content-text">
                                        <h1>¿Preguntas?</h1>
                                        <p className='fade-in-up'>
                                        Si hay alguna duda por favor comunicarse con el servicio al cliente por Mensaje De Texto. El Número es: 305-781-6144
                                        </p>
                                    </div> 
                                </div>
                            </div> 
                        </section>
                        <Footer/>
                </div>
            </div>
        </div> 
    );
}

export default Home;