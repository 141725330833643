import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from './LoadFadeIn';
import { FadeIn } from './FadeIn';
import NavBar  from './Navbar';
import Footer from './Footer';
import {Helmet} from "react-helmet";

const HowVaccinesWork = () => {

    useEffect(() => {

        LoadFadeIn();
        FadeIn();

        const cards = document.querySelectorAll('.white-card');

        if (window.innerWidth > 900) {

            cards.forEach(function (card) {

                    const cardContent = card.querySelector(".card-content");
                    const expandButton = card.querySelector('.expand-button');
                    const whiteCardImg = card.querySelector('.white-card-image');
                
                    const cardTextsShorts = card.querySelector('.short-text');
                    const cardTextsFulls = card.querySelector('.full-text');
                
                    let isExpanded = false;
                
                    cardContent.style.display = "flex";
                    cardContent.style.height = "400px";
                    whiteCardImg.style.width = "450px";
                    whiteCardImg.style.height = "400px";
                

                    cardTextsFulls.style.display = "none";
                    cardTextsShorts.style.display = "block";
                
                    expandButton.addEventListener('click', function () {
                        // Your click event code for each button goes here
                        if (!isExpanded) {
                            // Expand the card
                            cardContent.style.display = "block";
                            cardContent.style.height = "100%";
                            whiteCardImg.style.width = "100%";
                            whiteCardImg.style.height = "520px";
                
                            whiteCardImg.style.display = 'none';

                            cardTextsFulls.style.display = "block";
                            cardTextsShorts.style.display = "none";
                
                            expandButton.innerText = "Cerrar";
                            expandButton.style.float = 'right';
                            expandButton.style.marginBottom = "1em";
                        } else {
                            // Collapse the card
                            cardContent.style.display = "flex";
                            cardContent.style.height = "400px";
                            whiteCardImg.style.width = "450px";
                            whiteCardImg.style.height = "400px";
                
                            whiteCardImg.style.display = 'block';

                            cardTextsFulls.style.display = "none";
                            cardTextsShorts.style.display = "block";
                
                            expandButton.innerText = "más información";
                            expandButton.style.float = 'left';
                            expandButton.style.marginBottom = "0em";
                        }
                
                        isExpanded = !isExpanded; // Toggle the expanded state
                    });
            });

        } 
        else {

            // SHOW MOBILE

                const mobileCards = document.querySelectorAll('.mobile-card');
              
                mobileCards.forEach(function (mobileCard) {
                  const mobileContent = mobileCard.querySelector('.mobile-content');
                  const mobileReadMoreButton = mobileCard.querySelector('.mobile-read-more');
                  const mobileCloseButton = mobileCard.querySelector('.mobile-close');
                  const mobileFullText = mobileCard.querySelector('.mobile-full-text');
                  const mobileShortText = mobileCard.querySelector('.mobile-short-text');
                  const whiteCardImg = mobileCard.querySelector('.white-card-image');


                  mobileReadMoreButton.style.color = '#25528F';
                  mobileCloseButton.style.color = '#25528F';
                  
                  mobileReadMoreButton.style.width = '70%';
                  mobileCloseButton.style.width = '70%';
              
                  mobileReadMoreButton.addEventListener('click', function () {
                    mobileFullText.style.display = 'block';
                    mobileShortText.style.display = 'none';
                    mobileReadMoreButton.style.display = 'none';
                    mobileCloseButton.style.display = 'block';
                    whiteCardImg.style.display = 'none';
                  });
              
                  mobileCloseButton.addEventListener('click', function () {
                    mobileFullText.style.display = 'none';
                    mobileShortText.style.display = 'block';
                    mobileReadMoreButton.style.display = 'block';
                    mobileCloseButton.style.display = 'none';
                    whiteCardImg.style.display = 'block';
                  });
                });

        }

    }, []);

    return (
        <div>
            <div className='row no-gutters'>
                <NavBar />
                <Helmet>
                    <title>¿Cómo Funcionan Las Vacunas?</title>
                </Helmet>
                <div className='col-lg-10'>
                    <section className='page-header'>
                        <div className="page-header-img-body my-5">
                            <img className='page-header-img' src='assets/vacunas-work.webp' alt='Header'></img>
                        </div>   
                        <div className='page-header-title'>
                            <h1 className='fade-in-up-load'>¿Cómo Funcionan Las Vacunas?</h1>
                        </div>             
                    </section>
                    <section className='visit-us-section'>
                        <div className='container'>
                            <div className='white-card-body'>
                                <div class="card white-card">
                                    <div class="card-content">
                                        <div>
                                            <img  className='white-card-image' src='assets/moderna.png' alt='Header'/>
                                        </div>
                                        <div class="card-text">
                                            <h3 className='card-title'>Moderna</h3>
                                            <div className='card-second-child short-text'>
                                                <p className='fade-in-up'>
                                                La Vacuna de Moderna y la de Pfizer son muy parecidas pues ambas usan tecnología de mRNA. Así es como funciona la vacuna:                           
                                                </p>
                                            </div>
                                            <div className='card-second-child full-text'>
                                                <p className='fade-in-up'>
                                                La Vacuna de Moderna y la de Pfizer son muy parecidas pues ambas usan tecnología de mRNA. Así es como funciona la vacuna:                                                             
                                                </p>
                                                <p className='fade-in-up'>
                                                1. El mRNA de la parte que usa el SARS COV-2 para entrarse a las células humanas para causar infección es empacada en una bola de grasa microscópica.
                                                </p>
                                                <p className='fade-in-up'>
                                                2. Cuando la vacuna ha sido inyectada, una célula humana la deja entrar dentro de la célula.
                                                </p>
                                                <p className='fade-in-up'>
                                                3. Esa misma Célula usa el mRNA para crear proteínas parecidas a las de SARS COV-2. 
                                                </p>
                                                <p className='fade-in-up'>
                                                4. Esto alerta el Sistema Inmunológico.
                                                </p>
                                                <p className='fade-in-up'>
                                                5. Ahora si uno se infecta con SARS COV-2 después de ser vacunado, el sistema Inmunológico ya lo reconoce y para la infección.
                                                </p>
                                                
                                            </div>
                                            <a className="main-button expand-button"> más información
                                            </a>  
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div class="card mobile-card">
                                <div>
                                <img  className='white-card-image' src='assets/vacunas-moderna.webp' alt='Header'/>
                                </div>
                                <div class="mobile-content">
                                    <h3 className='card-title'>Moderna</h3>
                                    <div className='mobile-short-text'>
                                        <p>
                                        La Vacuna de Moderna y la de Pfizer son muy parecidas pues ambas usan tecnología de mRNA. Así es como funciona la vacuna:                           
                                        </p>
                                    </div>
                                    <div className='mobile-full-text'>
                                        <p>
                                        La Vacuna de Moderna y la de Pfizer son muy parecidas pues ambas usan tecnología de mRNA. Así es como funciona la vacuna:                                                             
                                        </p>
                                        <p>
                                        1. El mRNA de la parte que usa el SARS COV-2 para entrarse a las células humanas para causar infección es empacada en una bola de grasa microscópica.
                                        </p>
                                        <p>
                                        2. Cuando la vacuna ha sido inyectada, una célula humana la deja entrar dentro de la célula.
                                        </p>
                                        <p>
                                        3. Esa misma Célula usa el mRNA para crear proteínas parecidas a las de SARS COV-2. 
                                        </p>
                                        <p>
                                        4. Esto alerta el Sistema Inmunológico.
                                        </p>
                                        <p>
                                        5. Ahora si uno se infecta con SARS COV-2 después de ser vacunado, el sistema Inmunológico ya lo reconoce y para la infección.
                                        </p>
                                    </div>
                                    <a class="main-button mobile-read-more">más información</a>
                                    <a class="main-button mobile-close">Cerrar</a>
                                </div>
                            </div>
                            <div className='white-card-body'>
                                <div class="card white-card">
                                    <div class="card-content">
                                        <div>
                                            <img  className='white-card-image left-img' src='assets/pfizer.png' alt='Header'/>
                                        </div>
                                        <div class="card-text">
                                            <h3 className='card-title'>Pfizer/BioNTech</h3>
                                            <div className='card-second-child short-text'>
                                                <p className='fade-in-up'>
                                                La vacuna de Pfizer/BioNTech usa mRNA como la de Moderna. Sin embargo, su diferencia más significativa es el tiempo que se requiere que pase luego de la primera dosis hasta la segunda.                              
                                                </p>
                                            </div>
                                            <div className='card-second-child full-text'>
                                                <p className='fade-in-up'>
                                                La vacuna de Pfizer/BioNTech usa mRNA como la de Moderna. Sin embargo, su diferencia más significativa es el tiempo que se requiere que pase luego de la primera dosis hasta la segunda. Para la vacuna de Pfizer/BioNTec y de Moderna en algunos casos una tercera dosis es necesaria.                                  
                                                </p>
                                                <p className='fade-in-up'>
                                                Así funciona la vacuna:
                                                </p>
                                                <p className='fade-in-up'>
                                                1. El mRNA de la parte que usa el SARS COV-2 para entrarse a las células humanas para causar infección es empacada en una bola de grasa microscópica.
                                                </p>
                                                <p className='fade-in-up'>
                                                2. Cuando la vacuna a sido inyectada una célula humana la deja entrar dentro de la célula.
                                                </p>
                                                <p className='fade-in-up'>
                                                3. Esa misma Célula usa el mRNA para crear proteínas parecidas a las de SARS COV-2.
                                                </p>
                                                <p className='fade-in-up'>
                                                4. Esto alerta el Sistema Inmunológico.
                                                </p>
                                                <p className='fade-in-up'>
                                                5. Ahora si uno se infecta con SARS COV-2 después de ser vacunado el sistema Inmunológico ya lo reconoce y para la infección.
                                                </p>
                                            </div>
                                            <a className="main-button expand-button">más información
                                            </a>  
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div class="card mobile-card">
                                <div>
                                <img  className='white-card-image left-img' src='assets/vacunas-pfizer.webp' alt='Header'/>
                                </div>
                                <div class="mobile-content">
                                    <h3 className='card-title'>Pfizer/BioNTech</h3>
                                    <div className='mobile-short-text'>
                                        <p>
                                        La vacuna de Pfizer/BioNTech usa mRNA como la de Moderna. Sin embargo, su diferencia más significativa es el tiempo que se requiere que pase luego de la primera dosis hasta la segunda.                              
                                        </p>
                                    </div>
                                    <div className='mobile-full-text'>
                                        <p>
                                        La vacuna de Pfizer/BioNTech usa mRNA como la de Moderna. Sin embargo, su diferencia más significativa es el tiempo que se requiere que pase luego de la primera dosis hasta la segunda. Para la vacuna de Pfizer/BioNTec y de Moderna en algunos casos una tercera dosis es necesaria.                                  
                                        </p>
                                        <p>
                                        Así funciona la vacuna:
                                        </p>
                                        <p>
                                        1. El mRNA de la parte que usa el SARS COV-2 para entrarse a las células humanas para causar infección es empacada en una bola de grasa microscópica.
                                        </p>
                                        <p>
                                        2. Cuando la vacuna a sido inyectada una célula humana la deja entrar dentro de la célula.
                                        </p>
                                        <p>
                                        3. Esa misma Célula usa el mRNA para crear proteínas parecidas a las de SARS COV-2.
                                        </p>
                                        <p>
                                        4. Esto alerta el Sistema Inmunológico.
                                        </p>
                                        <p>
                                        5. Ahora si uno se infecta con SARS COV-2 después de ser vacunado el sistema Inmunológico ya lo reconoce y para la infección.
                                        </p>
                                    </div>
                                    <a class="main-button mobile-read-more">más información</a>
                                    <a class="main-button mobile-close">Cerrar</a>
                                </div>
                            </div>
                            <div className='white-card-body'>
                                <div class="card white-card">
                                    <div class="card-content">
                                        <div>
                                            <img  className='white-card-image' src='assets/johnson.png' alt='Header'/>
                                        </div>
                                        <div class="card-text">
                                            <h3 className='card-title'>Johnson & Johnson</h3>
                                            <div className='card-second-child short-text'>
                                                <p className='fade-in-up'>
                                                La vacuna de Johnson & Johnson es la más diferente de las tres. Esta vacuna usa tecnología nueva para parar la infección. Así es como funciona la vacuna:                                   
                                                </p>
                                            </div>
                                            <div className='card-second-child full-text'>
                                                <p className='fade-in-up'>
                                                La vacuna de Johnson & Johnson es la más diferente de las tres. Esta vacuna usa tecnología nueva para parar la infección. Así es como funciona la vacuna:                        
                                                </p>
                                                <p className='fade-in-up'>
                                                1. Dentro de un Adenovirus, el virus que causa la gripa, se encuentra la parte genética del SARS CoV-2 que causa que el virus infecte una célula.
                                                </p>
                                                <p className='fade-in-up'>
                                                2. Cuando el Adenovirus de la vacuna entra a la célula, crea mRNA y la célula comienza a crear proteínas similares a las del SARS COV-2.
                                                </p>
                                                <p className='fade-in-up'>
                                                3. El Sistema Inmunológico crea una respuesta y eso hace que el paciente tenga inmunidad al SARS CoV-2, que es el virus que causa COVID-19.
                                                </p>
                                            </div>
                                            <a className="main-button expand-button"> más información
                                            </a>  
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div class="card mobile-card">
                                <div>
                                <img  className='white-card-image' src='assets/vacunas-johnson.webp' alt='Header'/>
                                </div>
                                <div class="mobile-content">
                                    <h3 className='card-title'>Johnson & Johnson</h3>
                                    <div className='mobile-short-text'>
                                        <p>
                                        La vacuna de Johnson & Johnson es la más diferente de las tres. Esta vacuna usa tecnología nueva para parar la infección. Así es como funciona la vacuna:                                   
                                        </p>
                                    </div>
                                    <div className='mobile-full-text'>
                                        <p>
                                        La vacuna de Johnson & Johnson es la más diferente de las tres. Esta vacuna usa tecnología nueva para parar la infección. Así es como funciona la vacuna:                        
                                        </p>
                                        <p>
                                        1. Dentro de un Adenovirus, el virus que causa la gripa, se encuentra la parte genética del SARS CoV-2 que causa que el virus infecte una célula.
                                        </p>
                                        <p>
                                        2. Cuando el Adenovirus de la vacuna entra a la célula, crea mRNA y la célula comienza a crear proteínas similares a las del SARS COV-2.
                                        </p>
                                        <p>
                                        3. El Sistema Inmunológico crea una respuesta y eso hace que el paciente tenga inmunidad al SARS CoV-2, que es el virus que causa COVID-19.
                                        </p>
                                    </div>
                                    <a class="main-button mobile-read-more">más información</a>
                                    <a class="main-button mobile-close">Cerrar</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <br/><br/>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default HowVaccinesWork;