import React from 'react';
import { useEffect } from 'react';

const Footer = () => {

    return (
        <div>
           <section id="footer-section">
                <div className='footer-body container'>
                    <div className='footer row'>
                        <div className='footer-txt col-lg-6'>
                            <h4>Contacto:</h4>
                            <div className='row'>
                                <div className='col-lg-5 header'>Servicio Al Cliente:</div>
                                <div className='col-lg-7'>
                                    <a href="tel:305-781-6144">305-781-6144</a>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-5 header'>WhatsApp:</div>
                                <div className='col-lg-7'>
                                    <a href="tel:+57 (310)-323-9384">+57 (310)-323-9384</a>
                                </div>
                            </div>
                        </div>
                        <div className='footer-txt col-lg-6'>
                            <h4>Más Información:</h4>
                            <div>
                                <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/index.html">
                                    Recursos para continuar con la vacunación.
                                </a>
                            </div>
                            <div>
                                <a href="https://espanol.cdc.gov/coronavirus/2019-ncov/variants/variant-classifications.html#anchor_1679059484954">
                                    Variantes
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='copyright-txt'>
                            <br/>
                            <div>
                            © 2021 Vacunas Para Todos. All Rights Reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Footer;