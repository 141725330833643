import React from 'react';
import { useEffect } from 'react';

const TopMenu = () => {

    useEffect(() => {

        const TopMenu = document.getElementById("top-menu");

        const togglerButton = document.querySelector('.navbar-toggler');
        
        function isNavbarCollapsed() {
            const collapseElement = document.querySelector('.navbar-collapse');
            return !collapseElement.classList.contains('show');
        }

        let isCollapsed = true;

        togglerButton.addEventListener('click', function () {
            if (isCollapsed) {
                console.log('Navbar is collapsed.');
                isCollapsed = false;
                TopMenu.style.width = '80%';
                TopMenu.style.height = '100%';
            } else {
                console.log('Navbar is uncollapsed.');
                isCollapsed = true;
                TopMenu.style.width = '100%';
                TopMenu.style.height = '100px';
            }
        });

    }, []);


    return (
        <nav id='top-menu' className="navbar fixed-top navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/"><img decoding="async" id='logo_img' className='company_logo' src="assets/Vacunas_Logo.svg" alt="Logo"/></a>
           <button id="toggler" className="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="toggler-icon top-bar"></span>
              <span className="toggler-icon middle-bar"></span>
              <span className="toggler-icon bottom-bar"></span>
            </button>
              <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li id="homelink" className="nav-item">
                    <a id="navlink" className="nav-link" href="/">Página Principal</a>
                  </li>
                  <li id="aboutlink" className="nav-item">
                    <a id="navlink" className="nav-link" href="/como-funcionan-las-vacunas">¿Cómo Funcionan Las Vacunas?</a>
                  </li>
                  
                  <li id="aboutlink" className="nav-item">
                    <a id="navlink" className="nav-link" href="/preguntas-comunes">Preguntas Comunes</a>
                  </li>
                  
                  <li id="aboutlink" className="nav-item">
                    <a id="navlink" className="nav-link"  href="/seguridad-de-las-vacunas">Seguridad de las Vacunas</a>
                  </li>
                  <li id="aboutlink" className="nav-item">
                    <a id="navlink" className="nav-link" href="/recomendaciones-medicas">Recomendaciones Médicas</a>
                  </li>
                </ul>
            </div>
        </div>
      </nav>
      
    );
}

export default TopMenu;