import React from 'react';

const Navbar = () => {

    return (
        <div id="side-menu" className='col-lg-2'>
            <div className='side-menu-body'>
                <a href='/'><img src="assets/Vacunas_Logo.svg" alt="Logo"/></a>
                <div className='side-menu-links'>
                    <p><a href="/">Página Principal</a></p>
                    <p><a href="/como-funcionan-las-vacunas">¿Cómo Funcionan Las Vacunas?</a></p>
                    
                    <p><a href="/preguntas-comunes">Preguntas Comunes</a></p>
                    
                    <p><a href="/seguridad-de-las-vacunas">Seguridad de las Vacunas</a></p>
                    <p><a href="/recomendaciones-medicas">Recomendaciones Médicas</a></p>
                </div>
            </div>
        </div>
      
    );
}

export default Navbar;