import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';
import './styles/App.css';
import './styles/page.css';
import './styles/footer.css';
import './styles/home.css';
import './styles/navbar.css';

import App from './App';
import Home from './component/Home';
import TopMenu from './component/TopMenu';

import './component/LoadFadeIn';
import './component/FadeIn';

// VISIT

import HowVaccinesWork from './component/HowVaccinesWork';
import History from './component/History';
import CommonQuestions from './component/CommonQuestions';
import VaccineService from './component/VaccineService';
import VaccineSafety from './component/VaccineSafety';
import Recommendations from './component/Recommendations';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <TopMenu/>
    <Routes>
        <Route path="*" element={<App/>}>
          <Route index element={<Home/>}/>

          <Route path="como-funcionan-las-vacunas" element={<HowVaccinesWork/>} />
          
          <Route path="preguntas-comunes" element={<CommonQuestions/>} />
          
          <Route path="seguridad-de-las-vacunas" element={<VaccineSafety/>} />
          <Route path="recomendaciones-medicas" element={<Recommendations/>} />
        </Route>
      </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
